<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A Standard Reference Material (SRM) is certified to contain
        <number-value :value="conc" unit="\text{ppm}" />
        of an organic contaminant in soil. A chemist performed
        <stemble-latex content="$5$" />
        analyses on the SRM, and the results were
        <number-value :value="one" unit="\text{ppm}" />,
        <number-value :value="two" unit="\text{ppm}" />,
        <number-value :value="three" unit="\text{ppm}" />,
        <number-value :value="four" unit="\text{ppm}" />
        , and
        <number-value :value="five" unit="\text{ppm}" />.
      </p>
      <p class="mb-2">
        Determine the following before deciding whether experimental data differ from the expected
        result: (Assume a
        <stemble-latex content="$95\%$" />
        confidence level)
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Mean of the sample:}$"
        class="mb-3"
      />

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{Standard deviation:}$"
        class="mb-3"
      />

      <calculation-input v-model="inputs.input3" prepend-text="$\text{T-test:}$" class="mb-3" />

      <calculation-input
        v-model="inputs.input4"
        prepend-text="$\text{Degrees of freedom:}$"
        class="mb-3"
      />

      <p class="mb-2">Does the experimental data differ from the expected result?</p>
      <v-radio-group v-model="inputs.input5" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'ChemUPEI2210A2Q5',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
    one() {
      return this.taskState.getValueBySymbol('one').content;
    },
    two() {
      return this.taskState.getValueBySymbol('two').content;
    },
    three() {
      return this.taskState.getValueBySymbol('three').content;
    },
    four() {
      return this.taskState.getValueBySymbol('four').content;
    },
    five() {
      return this.taskState.getValueBySymbol('five').content;
    },
  },
};
</script>
